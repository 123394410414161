let am = angular.module('ep-admin', []);

am.config(['$stateProvider', $stateProvider => {
  $stateProvider.
    state('pax-search', {
      url: '/pax-search',
      controller: 'PaxSearchCtrl',
      style: 'feature/admin/admin.css',
      templateUrl: 'feature/admin/pax-search.html'
    });
}]);

am.factory('paxSearchSvc', [
  '$http', 'rest',
  ($http, rest) => {
    const svc = {};

    svc.getAuthToken = (gmin) => {
      return $http.post(rest.getRestUrlPrefix() + 'authenticate/impersonate/id/'+gmin);
    };

    return svc;
  }]
);

am.controller('PaxSearchCtrl', [ '$scope', '$rootScope', 'paxSearchSvc',
    ($scope, $rootScope, paxSearchSvc) => {
      $scope.databusSuffix = sessionStorage.currentLang === "fr" ? "-fr" : "";
      $scope.BAC = '';
      $scope.resourceUrl = 'databus/pax/pax-search-global-connect';
      
      $scope.validateBAC = function(){
        if($scope.BAC !== null && $scope.BAC.toString().length === 6){
          return true;
        }
      };
    
      $scope.searchPax = function(){
        if($scope.validateBAC()){
        	 $scope.filtersToApply = {bac:''};
        	 $scope.filtersToApply.bac = $scope.BAC;
             let resourceUrl = $scope.resourceUrl;
             $rootScope.$broadcast("searchPax", {any:  $scope.filtersToApply, resourceUrl});
        }
      };

      $rootScope.$on("impersonateEvent",function(event,args){
        paxSearchSvc.getAuthToken(args.any).then(res => {
          sessionStorage.clear();
          window.open('/#!/login?jwt=' + res.data.authToken, '_self');
          location.reload();
        });
      });
    }
  ]);
  
